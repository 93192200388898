import Vue from 'vue';
import { mapState } from 'vuex';
import Avatar from 'Profile/component/Avatar.vue';
import GoogleMap from 'Location/component/GoogleMap.vue';
import JoinForm from 'User/component/JoinForm.vue';
import LoginForm from 'User/component/LoginForm.vue';

import analytics from './js/util/analytics';
import { login } from './js/util/authenticator';
import { getCookie } from './js/util/cookie';
import getParam from './js/util/getparams';
import store from './js/store';
import collapseDirective from './directive/collapse';

import Modal from './component/Modal.vue';

Vue.config.productionTip = false;

export default function () {

    const shake = () => {
        document.querySelector('.homepage-signup h2').classList.add('shake');
        setTimeout(() => {
            document.querySelector('.homepage-signup h2').classList.remove('shake');
        }, 1000);
    };

    new Vue({// eslint-disable-line no-new
        el: '#homepage',
        name: 'home',
        store,

        components: {
            Avatar,
            GoogleMap,
            Modal,
            JoinForm,
            LoginForm
        },
        computed: mapState(['config', 'join', 'login']),
        directives: {
            collapse: collapseDirective
        },
        mounted() {
            if (getParam('join') && !getCookie('homeConverted')) {
                this.showJoin();
            }
        },
        methods: {
            scrollTop(analyticsText) {
                if (analyticsText) {
                    analytics('homepage', 'click', `Join Button - ${analyticsText}`);
                }
                window.scroll(0, 0);
                this.shake();
            },
            shake() {
                shake();
            },
            showLogin() {
                login();
            },
            showJoin(button) {
                if (store.state.siteConfig.siteShortCode === 'shuk' || store.state.siteConfig.siteShortCode === 'fsuk') {
                    this.$store.dispatch('showJoin');
                    analytics('uk-homepage', 'click', button);
                } else {
                    this.shake();
                }
            },
            scrollToSection(section) {
                document.querySelector(section).scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            },
        },
        watch: {
            'login.show': (value) => {
                if (!value) {
                    analytics('homepage', 'close', 'Login Modal');
                    if (!store.state.isMobile && document.querySelector('#homepage-video')) {
                        document.querySelector('#homepage-video').play();
                    }
                }
            }
        }
    });

    if (getCookie('homeConverted') && !store.state.config.data.disableLoginModalPopUp) {
        login();
    } else if (window.innerWidth > 767 && document.querySelector('#homepage-video')) {
        document.querySelector('#homepage-video').play();
        shake();
    }

    // --------------------- START fade in content on scroll --------------------- //
    function isElementInViewport(el) {
        const rect = el.getBoundingClientRect();
        return (
            rect.top <= (window.innerHeight || document.documentElement.clientHeight) - 100 &&
            rect.left >= 0 &&
            rect.bottom >= 0 &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    function pageScrollHandler() {
        const $webcam = document.querySelector('#webcam-video');

        if ($webcam && isElementInViewport($webcam)) {
            try {
                $webcam.play();
            } catch (e) {
                // catch exception thrown by some mobile browsers, no need to do anything with it
            }
        }
    }

    let pageScroll;
    window.addEventListener('scroll', () => {
        clearTimeout(pageScroll);
        pageScroll = setTimeout(() => {
            pageScrollHandler();
        }, 200);
    });

    pageScrollHandler();
    // --------------------- END fade in content on scroll --------------------- //

}
