import validator from 'validator';
import store from '../store';

/**
 * Validate a required value
 *
 * @param {sting|number|null} value
 * @param {string} name
 * @param {object|string} rule
 * @param {object} errors
 */
function validateRequired(value, name, rule, errors) {
    if (!validator.isLength(value, 1)) {
        errors[name] = {};
        errors[name].required = rule.message || store.state.gt.assets.validate.error_enter_name.replace('%name', name);
    }
    return errors;
}

/**
 * Validate a minimum number of characters
 *
 * @param {sting|number|null} value
 * @param {string} name
 * @param {object|string} rule
 * @param {object} errors
 */
function validateMin(value, name, rule, errors) {
    if (!validator.isLength(value, rule.value)) {
        errors[name] = {};
        errors[name].min = rule.message || store.state.gt.assets.validate.enter_at_least.replace('%value', rule.value);
    }
    return errors;
}

/**
 * Validate a maximum number of characters
 *
 * @param {sting|number|null} value
 * @param {string} name
 * @param {object|string} rule
 * @param {object} errors
 */
function validateMax(value, name, rule, errors) {
    if (!validator.isLength(value, 1, rule.value)) {
        errors[name] = {};
        errors[name].max = rule.message || store.state.gt.assets.validate.enter_less_than.replace('%value', rule.value);
    }
    return errors;
}

/**
 * Validate a minimum number of words
 *
 * @param {sting|number|null} value
 * @param {string} name
 * @param {object|string} rule
 * @param {object} errors
 */
function validateMinWords(value, name, rule, errors) {
    if (value.trim().replace(/\s+/gi, ' ').split(' ').length < rule.value) {
        errors[name] = {};
        errors[name].min = rule.message || store.state.gt.assets.validate.enter_at_least_words.replace('%value', rule.value);
    }
    return errors;
}

/**
 * Validate an email
 *
 * @param {sting|number|null} value
 * @param {string} name
 * @param {object|string} rule
 * @param {object} errors
 */
function validateEmail(value, name, rule, errors) {
    if (!validator.isEmail(value)) {
        errors[name] = {};
        errors[name].email = rule.message || store.state.gt.assets.validate.enter_valid_email;
    }
    return errors;

}

/**
 * Validate a pattern
 *
 * @param {sting|number|null} value
 * @param {string} name
 * @param {object|string} rule
 * @param {object} errors
 */
function validatePattern(value, name, rule, errors) {
    if (rule.inverse) {
        if (!validator.matches(value, rule.pattern)) {
            errors[name] = {};
            errors[name].pattern = rule.message;
        }
    } else if (validator.matches(value, rule.pattern)) {
        errors[name] = {};
        errors[name].pattern = rule.message;
    }
    return errors;
}

/**
 * Validate a that 2 fields match
 *
 * @param {sting|number|null} value
 * @param {string} name
 * @param {object|string} rule
 * @param {object} errors
 * @param {object} form
 */
function validateMatch(value, name, rule, errors, form) {
    form.errors.clear(rule.match);
    if (value !== form[rule.match]) {
        errors[name] = {};
        errors[name].match = rule.message;
    }

    return errors;
}

/**
 * Check a validation rule
 *
 * @param {sting|number|null} value
 * @param {string} name
 * @param {object|string} rule
 * @param {string} ruleType
 * @param {object} errors
 * @param {object} form
 */
function checkRule(value, name, rule, ruleType, errors, form) {
    switch (ruleType) {
        case 'email':
            return validateEmail(value, name, rule, errors);
        case 'max':
            return validateMax(value, name, rule, errors);
        case 'min':
            return validateMin(value, name, rule, errors);
        case 'min-words':
            return validateMinWords(value, name, rule, errors);
        case 'pattern':
            return validatePattern(value, name, rule, errors);
        case 'required':
            return validateRequired(value, name, rule, errors);
        case 'match':
            return validateMatch(value, name, rule, errors, form);
        default:
            return [];
    }
}

/**
 * Process the validation rules
 *
 * @param {sting|number|null} value
 * @param {string} name
 * @param {object|null} rules
 * @param {object} errors
 * @param {object} form
 */
export default function (value, name, rules, errors, form) {
    if (rules[name] && rules[name].length) {
        let gotError = false;
        rules[name].forEach((rule) => {
            if (!gotError) {
                if (typeof rule === 'string') {
                    errors = checkRule(value, name, rule, rule, errors, form);
                } else {
                    errors = checkRule(value, name, rule, rule.type, errors, form);
                }
                /* eslint no-prototype-builtins: "off" */
                if (errors.hasOwnProperty(name)) {
                    gotError = true;
                }
            }
        });
    }
    return errors;
}
