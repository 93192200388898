import { getCookie } from './cookie';
import vuexStore from '../store';

export function login(mediation = 'optional') {
    const { siteConfig } = vuexStore.state;

    // User already logged in
    if (siteConfig && siteConfig.user && siteConfig.user.id !== '') {
        return;
    }

    if (!navigator.credentials || !window.PasswordCredential) {
        if (mediation !== 'silent') {
            vuexStore.dispatch('showLogin');
        }
        return;
    }

    navigator.credentials.get({
        password: true,
        mediation
    }).then((cred) => {
        if (!cred) {
            if (mediation !== 'silent') {
                vuexStore.dispatch('showLogin');
            }
            return;
        }
        if (cred.type === 'password') {
            let fetchData;

            // If `password` is `undefined`, this API is older version
            // which means this is Chrome 51 - 59
            if (cred.password === undefined) {
                cred.passwordName = 'credential';
                cred.idName = 'identity';
                fetchData = {
                    method: 'POST',
                    credentials: cred
                };
            // If `password` is not `undefined`, this API is new version
            // which means this is Chrome 60 or later
            } else {
                const form = new FormData();
                form.append('identity', cred.id);
                form.append('credential', cred.password);

                fetchData = {
                    method: 'POST',
                    credentials: 'include',
                    body: form
                };
            }

            const url = getCookie('redirect');
            fetch(siteConfig.endpoints.login, fetchData).then(response => response.json().then((json) => {
                if (json.status) {
                    if (url) {
                        window.location.href = url;
                        return false;
                    }
                    window.location.reload();
                } else {
                    vuexStore.dispatch('showLogin');
                }
                return false;
            }));
        }
    }).catch(() => {
        vuexStore.dispatch('showLogin');
    });
}

export function logout() {
    if (navigator.credentials) {
        if (navigator.credentials.preventSilentAccess) {
            navigator.credentials.preventSilentAccess();
        } else {
            // For backwards compatibility Chrome < 60
            navigator.credentials.requireUserMediation();
        }
    }
}

export function store(id, password) {
    if (navigator.credentials) {
        const cred = new PasswordCredential({
            id,
            password
        });
        navigator.credentials.store(cred);
    }
}

export function registerLogoutClickHandler() {
    const $logoutButton = document.querySelector('#logout-button');
    if ($logoutButton) {
        $logoutButton.addEventListener('click', logout);
    }
}
