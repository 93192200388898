<template>
<div :class="['loading', {'loading-dark': dark, 'loading-small': small, 'loading-large': large}]">
    <img :src="siteConfig.data.imagesUrl.replace('REPLACE_ME', loadingImage)">
</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'Loading',
    computed: {
        ...mapState(['siteConfig']),
        loadingImage() {
            let image = this.$store.state.siteConfig.siteShortCode === 'face' ? 'faces-icon' : 'spiral';
            if (this.large) {
                image += '@2x';
            }
            return `${image}.png`;
        }
    },
    props: {
        dark: {
            type: Boolean,
            default: false
        },
        large: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        }
    }
};

</script>

<style lang="scss" scoped="true">
    @keyframes spin {
        from {transform:rotate(0deg);}
        to {transform:rotate(360deg);}
    }
    img {
        opacity: 0.6;
        animation-name: spin;
        animation-duration: 8s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
    .loading-large {
        height: 80px;
        width: 80px;
        img {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
    .btn .loading {
        font-size: 1.3em;
        margin: -3px auto;
        img {
            height: 20px;
            width: 20px;
            opacity: 0.8;
            margin-top: -3px;
        }
    }
</style>
