export default function (parameter) {
    const paramsString = window.location.search.substr(1);
    const params = {};

    if (paramsString) {
        const paramsArray = paramsString.split('&');
        paramsArray.forEach((param) => {
            const tmp = param.split('=');
            /* eslint prefer-destructuring: 0 */
            params[tmp[0]] = tmp[1];
        });
    }

    if (parameter && typeof params[parameter] !== 'undefined') {
        return params[parameter];
    } else if (parameter) {
        return null;
    }

    return params;
}
