import ajax from './ajax.fetch';
import Errors from './errors';
import validate from './validate';

export default class Form {
    /**
     * Create a new Form instance.
     *
     * @param {object} data
     * @param {array|null} rules
     */
    constructor(data, rules = [], reset = true) {
        this.originalData = data;
        this.shouldReset = reset;
        /* eslint no-restricted-syntax: "off", guard-for-in: "off" */
        for (const field in data) {
            this[field] = data[field];
        }
        this.rules = rules;
        this.errors = new Errors();
    }

    /**
     * Fetch all relevant data for the form.
     */
    data() {
        const data = {};
        for (const property in this.originalData) {
            data[property] = this[property];
        }
        return data;
    }

    /**
     * Reset the form fields.
     */
    reset() {
        for (const field in this.originalData) {
            this[field] = this.originalData[field];
        }
        this.errors.clear();
    }

    /**
     * Create a new entity
     * .
     * @param {string} url
     */
    create(url) {
        return this.submit('create', url);
    }

    /**
     * Update an existing entity
     * .
     * @param {string} url
     */
    update(url) {
        return this.submit('update', url);
    }

    /**
     * Replace existing validation rules - allows for partial validation on join/complete
     * .
     * @param {array} rules
     */
    updateRules(rules) {
        this.rules = rules;
    }

    /**
     * Validate the form or a single field
     *
     * @param {string|null} field
     */
    validate(field) {
        const errors = {};
        this.errors.clear(field);
        if (field) {
            validate(this[field], field, this.rules, errors, this);
            this.errors.recordOne(field, errors[field]);
        } else {
            for (const originalField in this.originalData) {
                validate(this[originalField], originalField, this.rules, errors, this);
            }
            this.errors.record(errors);
        }
        return !this.errors.any();
    }

    /**
     * Submit the form.
     *
     * @param {string} requestType
     * @param {string} url
     */
    submit(requestType, url) {
        if (!this.validate()) {
            return false;
        }
        return ajax[requestType](url, this.data())
            .then((response) => { if (this.shouldReset) { this.reset(); } return response; })
            .catch((error) => {
                if (error.status === 403) {
                    return error;
                }
                if (error.responseJSON) {
                    this.errors.record(error.responseJSON.message); return error;
                }
                error.response.then((response) => {
                    this.errors.record(response.message); return error;
                });
                const responseError = new Error(error.statusText);
                responseError.response = error.response;
                throw responseError;
            });
    }
}
