import 'whatwg-fetch';
import store from '../store';
import logErrorMessage from '../sentry';

export default {
    /**
     * Make a create request
     *
     * @param {string} url
     * @param {object} data
     */
    create(url, data) {
        return this.makeRequest('post', url, data);
    },
    /**
     * Make a delete request
     *
     * @param {string} url
     */
    delete(url, data) {
        return this.makeRequest('delete', url, data);
    },
    /**
     * Make a get request
     *
     * @param {string} url
     * @param {object} data
     */
    get(url, data) {
        return this.makeRequest('get', url, data);
    },
    /**
     * Make a head request
     *
     * @param {string} url
     * @param {object} data
     */
    head(url, data) {
        return this.makeRequest('head', url, data);
    },
    /**
     * Make an update request
     *
     * @param {string} url
     * @param {object} data
     */
    update(url, data) {
        return this.makeRequest('put', url, data);
    },
    /**
     * Make the ajax makeRequest
     *
     * @param {string} method
     * @param {string} url
     * @param {object} data
     */
    makeRequest(method, url, data) {
        const json = store.state.config;
        const { siteConfig } = store.state;

        if (data) {
            data = Object.assign({}, data);
        }
        if (url.indexOf('http') === -1) {
            if (json && json.endpoints && json.endpoints[url]) {
                /* eslint prefer-destructuring: "off" */
                url = json.endpoints[url];
            } else if (siteConfig && siteConfig.endpoints && siteConfig.endpoints[url]) {
                url = siteConfig.endpoints[url];
            } else {
                logErrorMessage('incorrect-endpoint', 'ajax', { method, url, data });
            }
        }

        if (data && data.id && method !== 'post') {
            const lastChar = url[url.length - 1];
            if (lastChar !== '/') {
                url += '/';
            }
            url += data.id;
            delete data.id;
        }

        if (data && (method === 'put' || method === 'post')) {
            data = JSON.stringify(data);
        } else if (data && Object.keys(data).length) {
            const params = Object.keys(data).map((key) => {
                if (Array.isArray(data[key])) {
                    const arrayValues = [];
                    data[key].forEach((value) => {
                        arrayValues.push(`${key}[]=${value}`);
                    });
                    return arrayValues.join('&');
                }

                return `${key}=${data[key]}`;
            }).join('&');
            url += `?${params}`;
        }

        function parseJSON(response) {
            if (response.status === 204) {
                return response;
            }
            if (response.headers.get('content-type').indexOf('application/json') !== -1) {
                return response.json();
            }
            if (method === 'head') {
                return response.headers;
            }
            return response.text();
        }

        function checkStatus(response) {
            if (response.status >= 200 && response.status < 300) {
                return parseJSON(response);
            }
            const error = new Error(response.statusText);
            error.response = parseJSON(response);
            error.response.status = response.status;
            throw error;
        }

        const params = {
            method,
            credentials: 'include',
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        };

        if (method !== 'head' && method !== 'get' && method !== 'delete' && data) {
            params.body = data;
            // Don't set content-type for CORS requests
            if (url.indexOf(window.location.host.split('.')[1]) !== -1) {
                params.headers['Content-Type'] = 'application/json';
            }
        }

        if (url.indexOf(window.location.host.split('.')[1]) === -1) {
            // Remove credentials and custom header for CORS requests
            delete params.credentials;
            delete params.headers['X-Requested-With'];
        }

        return fetch(url, params).then(checkStatus);
    }
};
