<template>
<div :class="['form-group', { 'has-error': hasError }]">
    <!--Added a child incase padding is added to component by parent e.g col-md-6 etc -->
    <div :class="['site-input', { 'input-group': buttonAddon }]">
        <input :type="type" :name="name" v-model="text" :disabled="disabled"
            @input="$emit('input', $event.target.value)" placeholder=""
            @keydown="$emit('keydown', $event)" @click="$emit('click', $event)"
            :autocomplete="autocomplete"
            :class="['form-control', 'input-lg', {'has-value': text}]" ref="field">
        <label :class="{'disabled': disabled}">{{ label }}</label>
        <i v-if="trailingicon && !hasError" :class="['trailing-icon', 'fa', `fa-${trailingicon}`]" @click="$emit('iconclick')"></i>
        <i v-if="hasError" class="trailing-icon fa fa-exclamation-triangle"></i>
        <slot></slot>
        <span class="help-block" v-if="hasError">{{ error }}</span>
    </div>
</div>
</template>

<script>
export default {
    name: 'SiteInput',
    computed: {
        error() {
            // Hack to make computed re-evaluate when value changes
            const check = this.text;
            return this.form ? this.form.errors.get(this.name) : false;
        },
        hasError() {
            // Hack to make computed re-evaluate when value changes
            const check = this.text;
            return this.form ? this.form.errors.has(this.name) : false;
        }
    },
    data() {
        return {
            text: this.value
        };
    },
    methods: {
        focus() {
            this.$refs.field.focus();
        }
    },
    watch: {
        value(newValue) {
            this.text = newValue;
        }
    },
    props: {
        autocomplete: {
            type: String
        },
        buttonAddon: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        form: {
            type: Object
        },
        label: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        trailingicon: {
            type: String
        },
        type: {
            type: String,
            default: 'text'
        },
        value: {
            type: String
        }
    }
};

</script>

<style lang="scss">
    // Styles in input.scss
</style>
