export default {
    namespaced: true,
    state: {
        cache: {}
    },
    mutations: {
        ADD_PROFILE(state, profile) {
            if (!state.cache[profile.id]) {
                state.cache[profile.id] = profile;
            }
        }
    }
};
