<template>
<form method="POST" :action="siteConfig.pages.login" @submit="submit" class="loginform">
    <SiteInput v-model="form.identity" @input="validate('identity')" :form="form" :label="gt.common.username"
        name="identity" autocomplete="username" ref="username" />
    <SiteInput v-model="form.password" @input="validate('password')" :form="form" :label="gt.login.password"
        @iconclick="showPassword = !showPassword" :trailingicon="showPassword ? 'eye-slash' : 'eye'"
        :type="showPassword ? 'text' : 'password'" name="credential" autocomplete="password" />
    <div class="checkbox">
        <input type="hidden" name="remember" value="0">
        <label><input type="checkbox" name="remember" value="1">{{ gt.login.remember_me }}</label>
        <a class="pull-right" :href="siteConfig.pages.forgot">{{ gt.login.forgot_password }}</a>
    </div>

    <div class="modal-footer">
        <a class="btn btn-link pull-left" @click="showJoin" href="#">{{ gt.login.not_a_member_yet }}</a>
        <button type="submit" class="btn btn-lg btn-primary">{{ gt.login.login }}</button>
    </div>
</form>
</template>

<script>
import { mapState } from 'vuex';
import Form from 'Site/js/util/form';
import SiteInput from 'Site/component/SiteInput.vue';

export default {
    name: 'LoginForm',
    components: {
        SiteInput
    },
    computed: {
        ...mapState(['gt', 'siteConfig'])
    },
    data() {
        return {
            form: new Form({
                identity: '',
                password: ''
            }, {
                identity: [
                    { type: 'required', message: this.$store.state.gt.assets.validate.error_enter_name.replace('%name', 'username') },
                    {
                        type: 'pattern',
                        pattern: /\W/i,
                        inverse: false,
                        message: this.$store.state.gt.login.invalid_username
                    }
                ],
                password: [
                    { type: 'required' }
                ]
            }),
            showPassword: false,
            submitted: false
        };
    },
    methods: {
        showJoin() {
            this.$parent.$emit('hideModal');
            // Give time for login modal to animate out
            setTimeout(() => {
                this.$emit('join');
            }, 250);
        },
        submit(e) {
            this.submitted = true;
            if (!this.validate()) {
                e.preventDefault();
            }
        },
        validate(field) {
            if (this.submitted) {
                return this.form.validate(field);
            }
            return true;
        }
    },
    mounted() {
        this.$on('show', () => {
            this.$nextTick(() => {
                this.$refs.username.focus();
            });
        });
    }
};

</script>

<style lang="scss">
.loginform {
    .checkbox {
        font-weight: normal;
        input {
            margin-top: 2px;
        }
    }
    .modal-footer {
        margin: -15px;
        margin-top: 0;
        .btn-link {
            padding-left: 0;
            padding-top: 13px;
        }
    }
}
.login-modal .modal-dialog {
    width: 350px;
}
</style>
