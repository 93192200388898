<template>
<div :class="['form-group', { 'has-error': hasError }]">
    <!--Added a child incase padding is added to component by parent e.g col-md-6 etc -->
    <div class="site-input">
        <select :name="name" v-model="text" @change="$emit('input', $event.target.value)"
            class="form-control input-lg" :class="{'has-value': text !== '' || hasEmptyOption}">
            <optgroup
                v-if="normalisedOptions[0].subOptions"
                v-for="option in normalisedOptions"
                :key="option.value"
                :label="option.text">
                <option v-for="suboption in  option.subOptions" :key="suboption.value" :value="suboption.value">{{ suboption.text }}</option>
            </optgroup>
            <option v-if="!normalisedOptions[0].subOptions" v-for="option in normalisedOptions" :key="option.value" :value="option.value">
                {{ option.text || option.label }}
            </option>
        </select>
        <label>{{ label }}</label>
        <span class="help-block" v-if="hasError">{{ error }}</span>
    </div>
</div>
</template>

<script>
export default {
    name: 'SiteSelect',
    computed: {
        error() {
            // Hack to make computed re-evaluate when value changes
            const check = this.text;
            return this.form ? this.form.errors.get(this.name) : false;
        },
        hasError() {
            // Hack to make computed re-evaluate when value changes
            const check = this.text;
            return this.form ? this.form.errors.has(this.name) : false;
        },
        hasEmptyOption() {
            return !!this.normalisedOptions.filter(option => option.value === '').length;
        },
        normalisedOptions() {
            if (Array.isArray(this.options)) {
                return this.options;
            }
            const options = [];
            Object.keys(this.options).forEach((index) => {
                if (typeof this.value === 'number') {
                    index = parseInt(index, 10);
                }
                let text = this.options[index];
                let subOptions = null;
                if (this.options[index].options) {
                    text = text.label;
                    subOptions = [];
                    Object.keys(this.options[index].options).forEach((subIndex) => {
                        subOptions.push({ value: subIndex, text: this.options[index].options[subIndex] });
                    });
                }
                options.push({ value: index, text, subOptions });
            });
            return options;
        }
    },
    data() {
        return {
            text: this.value
        };
    },
    watch: {
        value(newValue) {
            this.text = newValue;
        }
    },
    props: {
        form: {
            type: Object
        },
        label: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        options: {
            type: [Array, Object],
            required: true
        },
        value: {
            type: [Number, String]
        }
    }
};

</script>

<style lang="scss">
    // Styles in input.scss
</style>
