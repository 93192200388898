export default {
    inserted(el, binding) {
        const $checkTarget = document.querySelector(binding.value);
        // Optionally pass in a height for awkward sized collapsibles v-collapse:height="target"
        let height = binding.arg ? parseInt(binding.arg, 10) : null;

        if (!height) {
            setTimeout(() => {
                $checkTarget.classList.remove('collapse');
                height = $checkTarget.offsetHeight;
                $checkTarget.classList.add('collapse');
            }, 0);
        }

        el.addEventListener('click', (e) => {
            e.preventDefault();
            [...document.getElementsByClassName('navbar-toggle')].forEach((element) => {
                element.classList.add('collapsed');
            });

            const $target = document.querySelector(binding.value);

            if ($target.classList.contains('in')) {
                height = $checkTarget.offsetHeight;
                $target.classList.add('collapsing');
                setTimeout(() => {
                    $target.style.height = `${height}px`;
                    setTimeout(() => {
                        $target.style.height = '0px';
                    }, 50);
                }, 0);
                setTimeout(() => {
                    $target.classList.remove('collapsing');
                    $target.classList.remove('in');
                }, 300);
                el.classList.add('collapsed');
            } else {
                [...document.getElementsByClassName('navbar-collapse-js')].forEach((element) => {
                    element.classList.remove('in');
                });
                el.classList.remove('collapsed');

                $target.classList.add('in');
                $target.classList.add('collapsing');
                setTimeout(() => {
                    $target.style.height = `${height}px`;
                }, 1);
                setTimeout(() => {
                    $target.classList.remove('collapsing');
                    if (!binding.arg || (binding.arg && binding.modifiers.auto)) {
                        $target.style.height = 'auto';
                    }
                }, 300);
            }
            return false;
        });
    }
};
