<template>
<div :class="['modal', 'fade', {'in':inClass, 'modal-pinned': isPinned, 'modal-no-header': hideHeader}]"
    :style="{'display':display, 'z-index': zIndex}"
    @mousedown="hideModal($event, true)">
    <div :class="['modal-dialog', classes]" ref="modal">
        <div class="modal-content">
            <div class="modal-header" v-if="!hideHeader">
                <button v-if="closable" type="button" class="close" @click.stop="hideModal" :aria-label="gt.close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <button type="button" v-if="isPinned && submit" class="btn btn-default pull-right" @click="onSubmit">
                    {{ submit }}
                </button>
                <h4 class="modal-title">{{ title }}</h4>
            </div>
            <button v-if="hideHeader && closable" type="button" class="close" @click.stop="hideModal" :aria-label="gt.close">
                 <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <!-- @slot Buttons to be placed in the header when pinned-->
                <slot v-if="isPinned" name="buttons"></slot>
                <!-- @slot Modal contents -->
                <slot></slot>
            </div>
            <div class="modal-footer" v-if="!isPinned && !hideFooter">
                <!-- @slot Buttons to be rendered -->
                <slot name="buttons"></slot>
                <button type="button" class="btn btn-default" @click.stop="onClose">{{ gt.close }}</button>
                <button type="button" v-if="submit && showSubmitButton" class="btn btn-primary" @click="onSubmit">
                    {{ submit }}
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Vuex from 'vuex';

export default {
    name: 'Modal',
    provide: {
        // use with maskAdultAvailableInSection (from config) to override maskAdult flags
        componentAllowAdult: true
    },
    computed: {
        ...Vuex.mapState({
            gt: state => state.gt.common
        }),
        show() {
            return this.value;
        },
        isPinned() {
            return this.pinned && this.$store.state.isMobile;
        },
        zIndex() {
            if (this.hideHeader) {
                return this.classes.indexOf('upgrade') !== -1 ? 1051 : 1049;
            }
            return this.submit === 'Delete' ? 1051 : 1050;
        }
    },
    data() {
        return {
            backdrop: document.createElement('div'),
            body: document.querySelector('body'),
            classes: '',
            display: 'none',
            inClass: false,
            showSubmitButton: true
        };
    },
    methods: {
        hideModal(e, checkTarget) {
            if (!this.closable) {
                return;
            }
            if (checkTarget) {
                if (e.target === this.$el) {
                    // If we click within 15px of the edge of the screen and the modal is larger than the window
                    // we are most probably using the scroll bar so ignore
                    if (e.clientX >= (this.body.clientWidth) - 15 && this.$refs.modal.clientHeight > window.innerHeight) {
                        return;
                    }
                    this.emitCloseEvents();
                }
                return;
            }
            this.emitCloseEvents();
            if (this.isPinned) {
                window.history.back();
            }
        },
        /** Triggered when the modal is closed
         * @event close
         * @type {null}
         */
        onClose(e) {
            this.$emit('close');
            this.hideModal(e);
        },
        /** Triggered when the submit button is pressed
         * @event submit
         * @type {null}
         */
        onSubmit() {
            this.$emit('submit');

            if (this.callback) {
                this.emitCloseEvents();
                this.callback();
            }
            if (this.$children[0]) {
                this.$children[0].$emit('submit');
            }
        },
        emitCloseEvents() {
            if (this.$children[0]) {
                this.$children[0].$emit('hide');
            }
            this.$emit('input', false);
        }
    },
    mounted() {
        this.$on('hideModal', this.hideModal);
        this.$on('showSubmit', () => { this.showSubmitButton = true; });
        this.$on('hideSubmit', () => { this.showSubmitButton = false; });
        this.$on('setclasses', (classes) => { this.classes = classes; });
    },
    props: {
        callback: Function,
        closable: {
            type: Boolean,
            default: true
        },
        hideFooter: {
            type: Boolean,
            default: false
        },
        hideHeader: {
            type: Boolean,
            default: false
        },
        pinned: {
            type: Boolean,
            default: true
        },
        submit: String,
        title: String,
        value: Boolean
    },
    watch: {
        show(value) {
            if (value) {
                if (document.body.clientWidth < window.innerWidth) {
                    this.body.style.paddingRight = '15px';
                }
                this.display = 'block';
                this.backdrop.classList.add('modal-backdrop');
                this.backdrop.classList.add('fade');
                this.body.appendChild(this.backdrop);
                if (this.closable) {
                    this.body.classList.add('modal-open');
                    if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
                        const version = navigator.userAgent.match(/AppleWebKit\/(\d+)/);
                        if (parseInt(version[1], 10) < 605) {
                            this.body.classList.add('modal-open-ios');
                        }
                    }
                }
                setTimeout(() => {
                    this.inClass = true;
                    this.backdrop.classList.add('in');
                }, 150);

                if (this.$children[0]) {
                    this.$nextTick(() => {
                        this.$children[0].$emit('show');
                    });
                }

                if (this.isPinned) {
                    window.history.pushState({}, 'modal', '#modal');
                }
                window.onpopstate = function popstate() {
                    this.$emit('input', false);
                }.bind(this);
            } else {
                setTimeout(() => {
                    this.display = 'none';
                }, 150);
                this.inClass = false;
                this.body.removeChild(this.backdrop);
                const numOpenModals = document.querySelectorAll('.modal-backdrop').length;
                if (numOpenModals === 0) {
                    this.body.classList.remove('modal-open');
                    this.body.classList.remove('modal-open-ios');
                    this.body.style.paddingRight = '0';
                }
            }
        }
    }
};
</script>

<style lang="scss">
@import "../scss/variables";

.modal-pinned {
    .modal-dialog {
        margin: 0;
        width: 100% !important;
        height: 100%;
        transform: none !important;
    }
    .modal-content {
        border-radius: 0;
        box-shadow: none;
        border: 0;
        height: 100%;
    }
    .modal-body {
        height: calc(100% - 45px);
        overflow-y: auto;
    }
    .modal-header {
        padding: 5px 15px;
        border-radius: 0;
    }
    .modal-title {
        line-height: 1.6em;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .close {
        float: left;
        font-size: 27px;
        line-height: 1.25em;
        padding: 0 10px;
        margin: 0 0 0 -10px;
    }
}
.modal .modal-header {
    border: 0;
}
.modal.modal-no-header {
    .modal-body {
        padding-top: 0;
        height: 100%;
    }
    .close {
        color: #333;
        text-shadow: none;
    }
}
.modal-no-padding {
    .modal-body {
        padding: 0;
    }
}
.nav-tabs.modal-nav {
    margin: -15px;
    margin-bottom: 15px;
    display: flex;
    align-content: stretch;
    flex-direction: row;
    border:0;
    > li.active > a, > li.active > a:hover, > li > a:hover, > li.active > a:focus {
        background-color: #fff !important;
        color: $text-color;
        border: 0;
    }
    > li {
        flex: 1;
        text-align: center;
        margin-bottom: 0;
        &  > a {
            padding: 10px 20px;
            margin: 0;
            border: 0;
            border-radius: 0;
            color: #fff;
            border: 0;
            i {
                margin-right: 3px;
            }
        }
    }
}
@media (max-width: $screen-xs-max) {
    .nav-tabs.modal-nav > li > a {
        padding: 10px 6px;
    }
    .modal.modal-no-header .close {
        color: #fff;
        opacity: 0.8;
        text-shadow: 0 0 15px #000;
    }
    .modal .modal-upgrade-notice .close {
        color: $text-color;
        text-shadow: none;
    }
    .modal.modal-pinned.fade {
        opacity: 1;
        transition: none;
    }
}

@media (min-width: $screen-sm-min) {
    .modal-dialog {
        width: 602px;
    }
}
</style>
