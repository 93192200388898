import { getCookie, removeCookie, setCookie } from './cookie';
import logError from '../sentry';

let hasStorage = true;

try {
    localStorage.setItem('a', 'a');
    localStorage.removeItem('a');
    hasStorage = true;
} catch (exception) {
    hasStorage = false;
}

export function getStorage(key, session = false) {
    if (hasStorage) {
        if (session) {
            return JSON.parse(sessionStorage.getItem(key));
        }
        return JSON.parse(localStorage.getItem(key));
    }

    let value = getCookie(key);
    if (/^[{[]/.test(value)) {
        try {
            value = JSON.parse(value);
        } catch (e) {
            logError(`Parse JSON from cookie, key: ${key} message: ${e.message}`, 'JSON');
        }
    }

    return value;
}

export function setStorage(key, value, session = false) {
    if (hasStorage) {
        if (session) {
            sessionStorage.setItem(key, JSON.stringify(value));
        } else {
            localStorage.setItem(key, JSON.stringify(value));
        }
    } else {
        if (typeof value === 'object') {
            value = JSON.stringify(value);
        }
        return setCookie(key, value);
    }
    return value;
}

export function removeStorage(key, session = false) {
    if (hasStorage) {
        if (session) {
            sessionStorage.removeItem(key);
        } else {
            localStorage.removeItem(key);
        }
    }

    return removeCookie(key);
}
