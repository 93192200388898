import { openMessenger } from 'Messenger/js/init';

export default {
    bind(el, binding) {
        el.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            openMessenger(binding.value);
        });
    }
};
