<template>
<form class="join-form" @submit.prevent="submit" novalidate>
    <template v-if="!showUnsupportedCountry && !loading">
        <p>{{ t.no_card_required }}</p>
        <div class="join-form-fields">
        <SiteInput v-model="form.email" @input="validate('email')" type="email" :form="form" :label="t.your_email"
            name="email" autocomplete="email" />
        <span v-if="emailInUse" class="join-form-suggestions forgotten">
            <a :href="forgotUrl">{{ loginT.forgot_password }}</a>
        </span>
        <div class="row">
            <SiteInput v-model="form.username" class="col-md-7" @input="validate('username')" :form="form"
                autocomplete="off" :label="commonT.username" name="username" />
            <SiteSelect v-model="form.type" class="col-md-5 account-type" @input="validate('type')" :form="form" :label="t.account_type"
                name="type" :options="genderOptions" />
            <div class="col-md-12 join-form-suggestions" v-if="suggestions.length">
                <span>{{ t.suggestions }}: </span>
                <a v-for="(suggestion, index) in suggestions" href="#" :key="index" @click.prevent="addSuggestion(suggestion)">
                    {{ suggestion }}
                </a>
            </div>
        </div>
        <template v-if="!showInviteOnlyMessage">
            <SiteInput v-model="form.password" @input="validate('password')" :type="showPassword ? 'text' : 'password'"
                autocomplete="new-password" @iconclick="showPassword = !showPassword" :form="form" :label="t.password"
                :trailingicon="showPassword ? 'eye-slash' : 'eye'" name="password" />
        </template>
        <div :class="['form-group', {'has-error': form.errors.has('captcha')}]">
            <div v-show="isCaptchaRequired" class="g-recaptcha" ref="captcha"></div>
            <span class="help-block" v-if="form.errors.has('captcha')">{{ this.form.errors.get('captcha') }}</span>
        </div>
        <div :class="['form-group', 'terms', {'has-error': form.errors.has('terms')}]">
            <label for="terms">
                <input type="checkbox" name="terms" value="1" id="terms" v-model="form.terms"/>
                <span v-html="t.agree_terms"></span>
                <span class="help-block" v-if="form.errors.has('terms')">{{ this.form.errors.get('terms') }}</span>
            </label>
        </div>
        </div>
        <div>
            <p v-if="showInviteOnlyMessage">{{ t.site_invite_only_continue_to }}</p>
            <button type="submit" class="btn btn-primary btn-block btn-lg">{{ btnText }}</button>
        </div>
        <div class="login-prompt">
        <a v-if="modal" class="btn btn-link" href="#" @click.prevent="showLoginModal">{{ t.member_login }}</a>
        </div>
    </template>
    <loading v-if="loading" :large="true"></loading>
    <join-form-unsupported-country v-if="showUnsupportedCountry" :settings="showUnsupportedCountry"></join-form-unsupported-country>
</form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ajax from 'Site/js/util/ajax.fetch';
import analytics from 'Site/js/util/analytics';
import Form from 'Site/js/util/form';
import recaptchaLoader from 'User/js/util/recaptchaLoader';
import SiteInput from 'Site/component/SiteInput.vue';
import SiteSelect from 'Site/component/SiteSelect.vue';

import Loading from 'Site/component/Loading.vue';

import JoinFormUnsupportedCountry from './JoinFormUnsupportedCountry.vue';

export default {
    name: 'JoinForm',
    components: {
        JoinFormUnsupportedCountry,
        Loading,
        SiteInput,
        SiteSelect
    },
    computed: {
        ...mapState({
            captchaSiteKey: state => state.siteConfig.data.captchaSiteKey,
            forgotUrl: state => state.siteConfig.pages.forgot,
            hideCoupleAccountTypes: state => state.siteConfig.data.hideCoupleAccountTypes,
            preventSignupFromUnexpectedCountry: state => state.siteConfig.data.preventSignupFromUnexpectedCountry,
            t: state => state.gt.join,
            loginT: state => state.gt.login,
            commonT: state => state.gt.common,
            siteInvite: state => state.siteConfig.data.siteInvite,
            showSignupPrompt: state => state.join.showSignupPrompt
        }),
        btnText() {
            return this.showInviteOnlyMessage ? this.t.site_invite_only_continue_to : this.t.join_btn;
        },
        genderOptions() {
            const options = [
                { text: this.t.male, value: 1 },
                { text: this.t.female, value: 0 }
            ];
            if (!this.hideCoupleAccountTypes) {
                options.push({ text: this.t.couple, value: 2 });
            }
            return options;
        },
        text() {
            return this.showInviteOnlyMessage ? this.t.site_invite_only : this.t.agree_terms;
        },
        showInviteOnlyMessage() {
            if (this.siteInvite.email.length === 0) {
                let showInviteMessage = false;
                this.siteInvite.accountTypesForRedirect.forEach((type) => {
                    if (type === this.form.type) {
                        showInviteMessage = true;
                    }
                });
                return showInviteMessage;
            }
            return false;
        }
    },
    data() {
        return {
            emailInUse: false,
            enableCaptcha: typeof grecaptcha !== 'undefined',
            form: new Form({
                email: this.$store.state.siteConfig.data.siteInvite.email,
                type: this.$store.state.siteConfig.data.siteInvite.accountType,
                username: this.$store.state.siteConfig.data.siteInvite.username,
                password: '',
                'g-recaptcha-response': '',
                captcha: { recaptcha_response_field: '' },
                terms: false
            }, {
                email: [{ type: 'required', message: this.$store.state.gt.join.missing_email }, { type: 'email' }],
                type: [{ type: 'required', message: this.$store.state.gt.join.missing_account_type }],
                username: [
                    { type: 'required' },
                    { type: 'min', value: 6 },
                    { type: 'max', value: 30 },
                    {
                        type: 'pattern',
                        pattern: /\W/i,
                        inverse: false,
                        message: this.$store.state.gt.join.username_characters
                    },
                    {
                        type: 'pattern',
                        pattern: /[a-z_]/i,
                        inverse: true,
                        message: this.$store.state.gt.join.username_letter_underscore
                    }
                ],
                password: [
                    { type: 'required' }
                ],
                terms: [
                    { type: 'required' },
                    {
                        type: 'pattern',
                        pattern: /^false$/,
                        inverse: false,
                        message: this.$store.state.gt.join.terms_accept
                    },
                ]
            }),
            isCaptchaRequired: false,
            loading: false,
            showPassword: false,
            showUnsupportedCountry: false,
            submitted: false,
            suggestions: []
        };
    },
    methods: {
        ...mapActions(['showLogin']),
        addSuggestion(suggestion) {
            this.form.username = suggestion;
            this.validate('username');
        },
        checkSupportedCountry() {
            if (this.preventSignupFromUnexpectedCountry) {
                this.loading = true;
                ajax.get('unsupportedCountry').then((response) => {
                    if (response && response.code) {
                        this.showUnsupportedCountry = response;
                    }
                    this.loading = false;
                });
            }
        },
        initRecaptcha() {
            this.isCaptchaRequired = true;
            grecaptcha.render(this.$refs.captcha, { sitekey: this.captchaSiteKey });
        },
        showLoginModal() {
            this.$parent.$emit('hideModal');
            this.showLogin();
        },
        submit() {
            this.submitted = true;
            if (this.showInviteOnlyMessage) {
                return this.submitInviteOnly();
            }

            /* global grecaptcha */
            if (typeof grecaptcha !== 'undefined' && this.isCaptchaRequired) {
                this.form['g-recaptcha-response'] = grecaptcha.getResponse();
                this.form.captcha.recaptcha_response_field = this.form['g-recaptcha-response'];
            } else {
                delete this.form['g-recaptcha-response'];
            }

            const form = this.form.create('join');
            if (form) {
                this.loading = true;
                form.then((result) => {
                    this.$parent.$emit('hideModal');
                    analytics('joinform', 'submit', 'SUCCESS');
                    if (this.showSignupPrompt) {
                        analytics('scrollprompt', 'completed by user', null, null);
                    }
                    window.location.href = result.url;
                }).catch(({ response }) => {
                    response.then((data) => {
                        if (data.message.captcha) {
                            if (this.$store.state.recaptcha.isReady) {
                                this.initRecaptcha();
                            } else {
                                recaptchaLoader(this.$store, () => {
                                    this.initRecaptcha();
                                });
                            }
                        }
                        if (data.suggestions && data.suggestions.length) {
                            this.suggestions = data.suggestions;
                        } else {
                            this.suggestions = [];
                        }
                        this.emailInUse = data.emailInUse;
                    });
                    this.loading = false;
                });
            }
            return true;
        },
        submitInviteOnly() {
            this.form.rules.password = [];

            const values = {
                email: Object.assign(this.form.email),
                type: Object.assign(this.form.type),
                username: Object.assign(this.form.username)
            };
            const form = this.form.create('waitingList');
            if (form) {
                this.loading = true;
                form.then(() => {
                    this.$parent.$emit('hideModal');
                    this.loading = false;
                    window.location.href =
                        `${this.siteInvite.redirectUrl}?email=${values.email}&username=${values.username}&account_type=${values.type}`;
                }).catch(() => {
                    this.loading = false;
                });
            }
        },
        validate(field) {
            this.form.validate(field);
        }
    },
    mounted() {
        if (!this.modal) {
            this.checkSupportedCountry();
        }
        this.$on('show', () => {
            this.checkSupportedCountry();
        });
    },
    props: {
        abtesting: {
            type: Boolean,
            default: false
        },
        modal: {
            default: true
        }
    }
};

</script>

<style lang="scss">
@import "~Site/scss/variables";

.join-form {
    p {
        text-align: center;
        font-size: 16px;
        margin-bottom: 15px;
    }
    .login-prompt {
        text-align: center;
        margin-top: 10px;
    }
    .terms label {
        display: block;
        font-weight: normal;
        text-align: center;
        a {
            font-weight: bold;
        }
    }
}
.join-form-suggestions {
    text-align: left;
    margin-left: 15px;
    margin-top: -10px;
    padding-bottom: 10px;
    &.forgotten {
        margin-top: -33px;
        margin-right: 10px;
        float:right;
    }
    p {
        margin-bottom: 5px;
        text-align: left;
    }
    a {
        padding-left: 10px;
    }
}
@media (min-width: $screen-md-min) {
    .join-form .account-type {
        padding-left: 0;
    }
}
</style>
