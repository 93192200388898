export function getCookie(name) {
    return decodeURIComponent(document.cookie
        .replace(new RegExp(`(?:(?:^|.*;)\\s*${encodeURIComponent(name).replace(/[-.+*]/g, '\\$&')}\\s*\\=\\s*([^;]*).*$)|^.*$`), '$1')) || null;
}

export function setCookie(name, value) {
    document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}; domain=${document.domain}; path=/`;
}

export function removeCookie(name) {
    document.cookie = `${encodeURIComponent(name)}=; expires=Thu, 01-Jan-70 00:00:01 GMT; domain=${document.domain}; path=/`;
}
