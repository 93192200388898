import Vue from 'vue';
import Vuex from 'vuex';
import uniqueId from 'lodash.uniqueid';

import mediaStore from 'Media/js/store/media';
import profileStore from 'Profile/js/store/profiles';
import abtestStore from './store/abtest';

Vue.use(Vuex);

const $json = document.querySelector('#json');
let config = null;
let translations = null;

if ($json) {
    try {
        config = JSON.parse($json.textContent);
    } catch (e) {
        if (process.env.NODE_ENV !== 'production') {
            /* eslint no-console: 0 */
            console.error('Failed to parse JSON', 'main', e);
        }
    }
    if (config && config.translate) {
        translations = config.translate;
    }
}

const siteJson = document.querySelector('#siteConfig').textContent;
let siteConfig = null;

if (siteJson) {
    try {
        siteConfig = JSON.parse(siteJson);
        siteConfig.endpoints = SITE_CONFIG.endpoints;
        siteConfig.siteShortCode = SITE_CONFIG.siteShortCode;
    } catch (e) {
        if (process.env.NODE_ENV !== 'production') {
            console.error('Failed to parse JSON', 'siteConfig', e);
        }
    }
}

/*
const MediaViewer = SH.view.MediaViewer || {};
const ModalUpgradeNotice = SH.view.modal.ModalUpgradeNotice || {};
 */
const watcher = window.matchMedia('(max-width: 767px)');

const vuexStore = new Vuex.Store({
    modules: {
        abtest: abtestStore,
        media: mediaStore,
        profiles: profileStore
    },
    state: {
        t: translations,
        gt: SITE_CONFIG.translate,
        config,
        isMobile: watcher.matches,
        comments: {},
        ageVerify: {
            show: false
        },
        confirm: {
            text: '',
            callback: null,
            show: false,
            buttonText: ''
        },
        join: {
            show: false,
            showSignupPrompt: false
        },
        googleMaps: {
            loading: false,
            ready: false
        },
        login: {
            show: false
        },
        mediaPicker: {
            adultAllowed: false,
            callback: () => {},
            show: false,
            showPrivate: false,
            type: [0]
        },
        mediaviewer: {
            callback: null,
            id: null,
            media: [],
            show: false,
            start: null
        },
        message: {
            body: '',
            recipient: '',
            share: null,
            show: false,
            preset: '',
            presetMessages: false,
            saveCallback: null,
            title: '',
            metrics: {}
        },
        notifies: [],
        recaptcha: {
            isLoading: false,
            isReady: false,
        },
        report: {
            component: null,
            componentData: {},
            id: 0,
            show: false,
            title: '',
            type: 0,
            user: 0
        },
        siteConfig,
        siteInvite: {
            show: false
        },
        upgrade: {
            metrics: {},
            reason: null,
            show: false
        }
    },
    actions: {
        addMediaToViewer({ commit }, media) {
            commit('ADD_MEDIA_TO_VIEWER', media);
        },
        cacheComments({ commit }, { comments, totalItems, key }) {
            commit('CACHE_COMMENTS', { comments, totalItems, key });
        },
        googleMapsLoading({ commit }) {
            commit('GOOGLE_MAPS_LOADING');
        },
        googleMapsReady({ commit }) {
            commit('GOOGLE_MAPS_READY');
        },
        notify({ commit }, notify) {
            commit('ADD_NOTIFY', notify);
        },
        showConfirm({ commit }, confirm) {
            commit('SHOW_CONFIRM', confirm);
        },
        showLogin({ commit }) {
            commit('SHOW_LOGIN');
        },
        showAgeConfirmation({ commit }) {
            commit('SHOW_AGE_CONFIRMATION');
        },
        sendMessage({ commit, state }, recipient = {}) {
            let messageData = recipient;
            if (typeof recipient === 'string') {
                messageData = { recipient };
            }
            if (state.siteConfig.data.permissions.canSendMessages) {
                commit('SEND_MESSAGE', messageData);
            } else {
                commit('SHOW_UPGRADE', { reason: 'send-message', metrics: messageData.metrics });
            }
        },
        sendAdminMessage({ commit, state }, data) {
            let messageData = data;
            if (typeof data === 'string') {
                messageData = { recipient: data };
            }
            messageData.presetMessages = state.config.data && state.config.data.presetMessages ? state.config.data.presetMessages : false;
            if (state.siteConfig.data.permissions.canSendMessages) {
                commit('SEND_MESSAGE', messageData);
            } else {
                commit('SHOW_UPGRADE', { reason: 'send-message' });
            }
        },
        sendReport({ commit }, report) {
            commit('SEND_REPORT', report);
        },
        showJoin({ commit }) {
            commit('SHOW_JOIN');
        },
        setSignupPrompt({ commit }, value) {
            commit('SET_SIGNUP_PROMPT', value);
        },
        showMediaPicker({ commit }, {
            adultAllowed = false,
            callback = null,
            showPrivate = false,
            type = [0]
        }) {
            commit('SHOW_MEDIA_PICKER', {
                adultAllowed,
                callback,
                showPrivate,
                type
            });
        },
        showMediaViewer({ commit }, {
            adultAllowed,
            callback,
            media,
            start,
            startId,
            id,
            metrics
        }) {
            if (startId && !id) {
                id = startId;
            }
            commit('SHOW_MEDIA_VIEWER', {
                adultAllowed,
                callback,
                media,
                id,
                start,
                metrics
            });
        },
        showSiteInvite({ commit }) {
            commit('SHOW_SITE_INVITE');
        },
        showUpgrade({ commit }, { reason, metrics = {} }) {
            commit('SHOW_UPGRADE', { reason, metrics });
        },
        setRecaptchaLoading({ commit }) {
            commit('SET_RECAPTCHA_LOADING');
        },
        setRecaptchaReady({ commit }) {
            commit('SET_RECAPTCHA_READY');
        },
    },
    mutations: {
        ADD_MEDIA_TO_VIEWER(state, media) {
            state.mediaviewer.media = state.mediaviewer.media.concat(media);
        },
        ADD_NOTIFY(state, notify) {
            notify.id = uniqueId();
            notify.fade = false;
            state.notifies.push(notify);
            setTimeout(() => {
                notify.fade = true;
                setTimeout(() => {
                    state.notifies = state.notifies.filter(n => n.id !== notify.id);
                }, 300);
            }, notify.duration || 5000);
        },
        REMOVE_NOTIFY(state, notify) {
            notify.fade = true;
            setTimeout(() => {
                state.notifies = state.notifies.filter(n => n.id !== notify.id);
            }, 300);
        },
        CACHE_COMMENTS(state, { comments, totalItems, key }) {
            state.comments[key] = { comments, totalItems };
        },
        GOOGLE_MAPS_LOADING(state) {
            state.googleMaps.loading = true;
        },
        GOOGLE_MAPS_READY(state) {
            state.googleMaps.ready = true;
        },
        SEND_MESSAGE(state, {
            recipient = null,
            preset = '',
            presetMessages = false,
            saveCallback = null,
            share = null,
            title = null,
            metrics = null
        }) {
            state.message.preset = preset;
            state.message.recipient = recipient;
            state.message.saveCallback = saveCallback;
            state.message.share = share;
            state.message.metrics = metrics;
            if (title) {
                state.message.title = title;
            } else if (recipient) {
                if (state.isMobile === true) {
                    state.message.title = state.gt.messaging.send_message;
                } else {
                    state.message.title = state.gt.messaging.send_message_to.replace('%username', recipient);
                }
            } else {
                state.message.title = state.gt.messaging.send_message;
            }
            state.message.presetMessages = presetMessages;
            state.message.show = true;
        },
        SEND_REPORT(state, report) {
            state.report.component = report.component;
            state.report.componentData = report.componentData;
            state.report.id = report.id;
            state.report.title = report.title;
            state.report.type = report.type;
            state.report.user = report.user;
            state.report.show = true;
        },
        SET_IS_MOBILE(state, value) {
            state.isMobile = value;
        },
        SHOW_CONFIRM(state, confirm) {
            state.confirm.text = confirm.text;
            state.confirm.callback = confirm.callback;
            state.confirm.buttonText = confirm.buttonText || state.gt.common.delete;
            state.confirm.show = true;
        },
        SHOW_LOGIN(state) {
            state.login.show = true;
        },
        SHOW_MEDIA_PICKER(state, {
            adultAllowed = false,
            callback = null,
            showPrivate = false,
            type = [0]
        }) {
            state.mediaPicker.show = true;
            state.mediaPicker.adultAllowed = adultAllowed;
            state.mediaPicker.callback = callback;
            state.mediaPicker.showPrivate = showPrivate;
            state.mediaPicker.type = type;
        },
        SHOW_JOIN(state) {
            state.join.show = true;
        },
        SHOW_AGE_CONFIRMATION(state) {
            state.ageVerify.show = true;
        },
        SHOW_UPGRADE(state, { reason, metrics = {} }) {
            state.upgrade.metrics = metrics;
            state.upgrade.reason = reason;
            state.upgrade.show = true;
        },
        SET_SIGNUP_PROMPT(state, value) {
            state.join.showSignupPrompt = value;
        },
        SHOW_SITE_INVITE(state) {
            state.siteInvite.show = true;
        },
        SHOW_MEDIA_VIEWER(state, data) {
            state.mediaviewer.adultAllowed = data.adultAllowed || false;
            state.mediaviewer.callback = data.callback;
            state.mediaviewer.id = data.id;
            state.mediaviewer.start = data.start;
            if (!Array.isArray(data.media)) {
                data.media = [data.media];
            }
            state.mediaviewer.media = data.media;
            state.mediaviewer.page = data.page;
            state.mediaviewer.show = true;
        },
        SET_RECAPTCHA_LOADING(state) {
            state.recaptcha.isLoading = true;
        },
        SET_RECAPTCHA_READY(state) {
            state.recaptcha.isReady = true;
        },
    }
});

watcher.addListener((response) => {
    vuexStore.commit('SET_IS_MOBILE', response.matches);
});

export default vuexStore;
