import store from 'Site/js/store';

export default {
    bind(el, binding) {
        el.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            window.location.href = `${store.state.siteConfig.pages.chat}?room=${binding.value}`;
        });
    }
};
