import Vue from 'vue';
import Raven from 'raven-js';
import RavenVue from 'raven-js/plugins/vue';
import store from './store';


export default function (message, tag, data = {}) {
    const enable = store && store.state && store.state.siteConfig && store.state.siteConfig.data.logErrors && store.state.siteConfig.user &&
    store.state.siteConfig.user.id;
    if (process.env.NODE_ENV === 'production' && enable) {
        Raven.captureMessage(message, {
            tags: { message: tag },
            extra: { data }
        });
    } else {
        /* eslint no-console: 0 */
        console.error(message, tag, data);
    }
}

export function initErrorLogging() {
    const enable = store && store.state && store.state.siteConfig && store.state.siteConfig.data.logErrors && store.state.siteConfig.user &&
    store.state.siteConfig.user.id;
    if (enable) {
        Raven.config('https://156d2a7e25b54d958a7f1db43ba352c0@app.getsentry.com/68422', {
            release: store.state.siteConfig.data.releaseDate,
            ignoreErrors: [
                // Random plugins/extensions
                'top.GLOBALS',
                // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error. html
                '__gCrWeb.autofill.extractForms',
                'DealPly is not defined',
                'originalCreateNotification',
                'canvas.contentDocument',
                'MyApp_RemoveAllHighlights',
                'http://tt.epicplay.com',
                'Can\'t find variable: ZiteReader',
                'jigsaw is not defined',
                'ComboSearch is not defined',
                'http://loading.retry.widdit.com/',
                'atomicFindClose',
                // Facebook borked
                'fb_xd_fragment',
                // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
                // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
                'bmi_SafeAddOnload',
                'EBCallBackMessageReceived',
                // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
                'conduitPage',
                'ResizeObserver loop limit exceeded',
                '_avast_submit',
                // Can be removed once sasumng browser is updated https://github.com/SamsungInternet/support/issues/56
                '.ToString'
            ],
            ignoreUrls: [
                // Facebook flakiness
                /graph\.facebook\.com/i,
                // Facebook blocked
                /connect\.facebook\.net\/en_US\/all\.js/i,
                // Woopra flakiness
                /eatdifferent\.com\.woopra-ns\.com/i,
                /static\.woopra\.com\/js\/woopra\.js/i,
                // Chrome extensions
                /extensions\//i,
                /^chrome:\/\//i,
                // Other plugins
                /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
                /webappstoolbarba\.texthelp\.com\//i,
                /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
            ]
        }).addPlugin(RavenVue, Vue).install();
        Raven.setUserContext({
            username: store.state.siteConfig.user.name,
            id: store.state.siteConfig.user.id,
            level: store.state.siteConfig.userLevel
        });
    }
}
