export default {
    namespaced: true,
    state: {
        cache: {}
    },
    mutations: {
        ADD_MEDIA(state, data) {
            if (!state.cache[data.key] || !state.cache[data.key].length) {
                state.cache[data.key] = data.media;
            }
        }
    },
};
