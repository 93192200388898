<template>
    <div v-if="useDynamicMap" class="google-map">
        <div id="mapCanvas" ref="map"></div>
        <a v-if="showDirections" class="btn btn-primary btn-google-map" target="_new"
            :href="`https://maps.google.com?saddr=Current+Location&daddr=${mapSettings.latitude},${mapSettings.longitude}`">
        <i class="fa fa-map-marker"></i> {{ t.directions }}</a>
    </div>
    <div v-else class="google-map">
        <iframe
            width="100%"
            height="100%"
            frameborder="0"
            scrolling="no"
            :src="`https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${mapSettings.latitude},${mapSettings.longitude}&zoom=${mapSettings.zoom}`">
        </iframe>
    </div>
</template>

<script>
import MarkerClusterer from 'markerclustererplus-node';
import googleMapsLoader from '../js/googleMapsLoader';

export default {
    name: 'GoogleMap',
    data() {
        return {
            apiKey: this.$store.state.siteConfig.data.googleMapsApi,
            map: {},
        };
    },
    computed: {
        useDynamicMap() {
            if ((typeof this.locations === 'array' && this.locations.length > 1)
                || this.clusterer) {
                return true;
            }

            return false;
        }
    },
    methods: {
        calculator(markers, numStyles) {
            let index = 0;
            let count = 0;
            let id = null;
            let title = '';
            let url;

            markers.forEach((marker) => {
                count += marker.count;
            });

            if (markers.length === 1) {
                /* eslint prefer-destructuring: "off" */
                id = markers[0].id;
                title = markers[0].name;
                url = markers[0].url;
            } else {
                title = `${this.t.more}...`;
            }

            let dv = count;
            while (dv !== 0) {
                dv = parseInt(dv / 10, 10);
                index += 1;
            }

            index = Math.min(index, numStyles);
            return {
                text: count,
                index,
                title,
                id,
                url
            };
        },
        createMarker(location) {
            const latLng = new google.maps.LatLng(location.latitude, location.longitude);
            return new google.maps.Marker({
                position: latLng,
                name: location.name || location.title,
                title: location.name || location.title,
                count: location[this.mapSettings.countKey || 0],
                id: location.id || 0,
                url: location.url || ''
            });
        },
        addMarkers() {
            const markers = [];

            if (Array.isArray(this.locations)) {
                this.locations.forEach((location) => {
                    markers.push(this.createMarker(location));
                });
                if (this.clusterer) {
                    const options = {
                        calculator: this.calculator,
                        minimumClusterSize: 1
                    };
                    const markerClusterer = new MarkerClusterer(this.map, markers, options);
                    google.maps.event.addListener(markerClusterer, 'click', this.markerClick);
                } else {
                    markers.forEach((marker) => {
                        marker.setMap(this.map);
                    });
                }
            } else {
                const marker = this.createMarker(this.locations);
                marker.setMap(this.map);
            }
        },
        markerClick(cluster) {
            /* eslint no-underscore-dangle: 0 */
            if (cluster.clusterIcon_.sums_.id) {
                window.location.href = cluster.clusterIcon_.sums_.url;
                this.map.setOptions({ maxZoom: this.map.getZoom() });
            }
        },
        setup() {
            this.map = new google.maps.Map(this.$refs.map, {
                zoom: parseInt(this.mapSettings.zoom, 10),
                center: new google.maps.LatLng(this.mapSettings.latitude, this.mapSettings.longitude),
                mapTypeControl: false,
                disableDefaultUI: true,
                mapTypeId: google.maps.MapTypeId.ROADMAP
            });

            this.addMarkers();
        }
    },
    mounted() {
        if (!this.useDynamicMap) {
            return;
        }
        googleMapsLoader();
        if (!this.$store.state.googleMaps.ready) {
            window.addEventListener('google.maps.ready', this.setup);
        } else {
            this.setup();
        }
    },
    props: {
        clusterer: {
            type: Boolean,
            default: true
        },
        /**
         * Google Map settings via config.
         */
        mapSettings: {
            type: Object,
            required: true
        },
        locations: {
            type: [Array, Object],
            required: true
        },
        t: {
            type: Object,
            required: true
        },
        showDirections: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
    .google-map {
        height: 100%;
        position: relative;
    }
    .btn-google-map {
        position: absolute;
        top: 5px;
        right: 5px;
        border-radius: 0;
        i {
            margin-right: 3px;
        }
    }
    #mapCanvas{
        border-radius: 2px;
        border: 1px solid #ccc;
        height: 100%;
        width: 100%;
    }
</style>
