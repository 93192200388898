import store from 'Site/js/store';

export default function () {
    let key = '';
    if (store.state.siteConfig.data.googleMapsApi) {
        key = `&key=${store.state.siteConfig.data.googleMapsApi}`;
    }

    if (!store.state.googleMaps.ready && !store.state.googleMaps.loading) {
        store.dispatch('googleMapsLoading');
        const lang = store.state.siteConfig.siteLocale;
        const scriptTag = document.createElement('script');
        const firstScriptTag = document.getElementsByTagName('script')[0];
        scriptTag.src = `https://maps.googleapis.com/maps/api/js?libraries=places&callback=initializeMaps&language=${lang}${key}`;
        firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag);
    }
}

window.initializeMaps = () => {
    store.dispatch('googleMapsReady');

    if (CustomEvent) {
        window.dispatchEvent(new CustomEvent('google.maps.ready'));
    }
};
