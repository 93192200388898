let store = {};
let callback = null;

export default function (vuexStore, aCallback = null) {
    store = vuexStore;
    callback = aCallback;

    if (!store.state.recaptcha.isReady && !store.state.recaptcha.isLoading) {
        store.dispatch('setRecaptchaLoading');

        const scriptTag = document.createElement('script');
        const firstScriptTag = document.getElementsByTagName('script')[0];

        scriptTag.src = 'https://www.google.com/recaptcha/api.js?render=explicit&onload=initializeRecaptcha';
        firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag);
    }
}

window.initializeRecaptcha = () => {
    store.dispatch('setRecaptchaReady');
    if (typeof callback === 'function') {
        callback();
    }
};
