import analytics from '../util/analytics';
import { getStorage, setStorage } from '../util/storage';

export default {
    namespaced: true,
    state: {
        tests: {},
        testCase: ''
    },
    actions: {
        init({ commit }, options) {
            options.selectedTest = getStorage(`abtest-${options.name}`, true);

            if (!options.selectedTest) {
                const randomNumber = Math.random() * 100;
                options.a = Object.assign({ name: 'A', percentage: 50 }, options.a);
                options.b = Object.assign({ name: 'B', percentage: 50 }, options.b);
                options.selectedTest = 'a';

                if (randomNumber > options.a.percentage) {
                    options.selectedTest = 'b';
                }

                setStorage(`abtest-${options.name}`, options.selectedTest, true);
            }

            commit('ADD_TEST', options);
            analytics('abtest', options.name, `init-${options[options.selectedTest].name}`);
        },
        complete({ state }, testName) {
            const test = state.tests[testName];
            analytics('abtest', test.name, `complete-${test[test.selectedTest].name}`);
        },
        setTestCase({ commit }, string) {
            analytics('abtest', `available-${string}`);
            commit('ADD_TEST_CASE', string);
        },
    },
    mutations: {
        ADD_TEST(state, options) {
            state.tests[options.name] = options;
        },
        ADD_TEST_CASE(state, string) {
            state.testCase = string;
        }
    }
};
