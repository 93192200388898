import store from 'Site/js/store';
import { getStorage, setStorage } from 'Site/js/util/storage';

let messengerInstance = null;

export default function () {

    const $messenger = document.querySelector('#messenger');

    if (!$messenger) {
        return;
    }

    // Temporary translation workaround for the content that was defined in SCSS
    $messenger.insertAdjacentHTML('beforeend', `<style>#messenger:after {content:"${store.state.gt.messenger.messenger}"} </style>`);
    $messenger.insertAdjacentHTML(
        'beforeend',
        `<style>#messenger:not([data-connection="connected"]).send-panel:after {content:"${store.state.gt.messenger.currently_offline}"}</style>`,
    );

    if (!(store.state.siteConfig.messenger && !document.querySelectorAll('#setup').length)) {
        return;
    }

    if (!(store.state.siteConfig.messenger.privacySettings.messageAll || store.state.siteConfig.messenger.privacySettings.messageFriendsOnly)) {
        return;
    }

    const userId = store.state.siteConfig.messenger.core.id;

    /* eslint no-new: 0 */
    const createMessenger = () => {
        import(/* webpackChunkName: "messenger" */ './view/messenger').then((Messenger) => {
            messengerInstance = new Messenger.default({
                el: '#messenger'
            }, store.state.siteConfig.messenger);
            $messenger.setAttribute('data-status', '');
        });
    };

    if (getStorage(`messenger_${userId}_states`)) {
        const messengerData = getStorage(`messenger_${userId}_states`);
        if (messengerData.settings.online) {
            createMessenger();
        }
    } else {
        createMessenger();
    }

    const setupMessenger = () => {
        if (!messengerInstance) {
            createMessenger();
        } else {
            messengerInstance.initialise();
            $messenger.setAttribute('data-status', '');
        }
        const messengerData = getStorage(`messenger_${userId}_states`);
        messengerData.settings.online = true;
        setStorage(`messenger_${userId}_states`, messengerData);
    };

    const $menuButton = document.querySelector('#messenger-menu-button');
    if ($menuButton) {
        $menuButton.addEventListener('click', () => {
            if (document.querySelector('#messenger').getAttribute('data-status') === 'offline') {
                setupMessenger();
            }
        });
    }

    if ($messenger) {
        $messenger.addEventListener('click', () => {
            if ($messenger.getAttribute('data-status') === 'offline') {
                setupMessenger();
            }
        });
    }

    document.addEventListener('click', (event) => {
        if (event.target.matches('.openMessengerChat, .openMessengerChat *')) {
            event.preventDefault();
            const $el = event.target.tagName === 'A' ? event.target : event.target.parentElement;
            const id = $el.getAttribute('rel');
            const username = $el.getAttribute('data-nick');

            if (id) {
                messengerInstance.outgoingChatRequest(id, username);
            }
        }
    }, false);
}

export function openMessenger(userId) {
    messengerInstance.outgoingChatRequest(userId);
}
