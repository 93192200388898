<template>
<form @submit.prevent="submit" novalidate>
    <p>{{ body }}</p>
    <template v-if="!sent">
        <loading v-if="loading"></loading>
        <div :class="['form-group', { 'has-error': form.errors.has('email') }]">
            <input name="email" v-model="form.email" :placeholder="t.your_email" class="form-control input-lg" type="email">
            <span class="help-block" v-if="form.errors.has('email')">{{ form.errors.get('email') }}</span>
        </div>
        <div class="form-group">
            <button type="submit" class="btn btn-primary btn-block btn-lg">{{ commonT.add }}</button>
        </div>
    </template>
</form>
</template>

<script>
import { mapState } from 'vuex';
import Form from 'Site/js/util/form';
import Loading from 'Site/component/Loading.vue';

export default {
    name: 'JoinFormUnsupportedCountry',
    components: {
        Loading
    },
    computed: {
        ...mapState({
            t: state => state.gt.join,
            commonT: state => state.gt.common
        }),
    },
    data() {
        return {
            body: this.settings.messageBody,
            form: new Form({
                countryCode: this.settings.code,
                email: ''
            }, {
                email: [{ type: 'required' }, { type: 'email' }]
            }),
            loading: false,
            sent: false
        };
    },
    methods: {
        submit() {
            const form = this.form.create('unsupportedCountry');
            if (form) {
                this.loading = true;
                form.then(() => {
                    this.sent = true;
                    this.loading = false;
                    this.body = this.t.launch_email.replace('%email', this.form.email);
                }).catch(() => {
                    this.loading = false;
                });
            }
        }
    },
    props: {
        settings: {
            type: Object
        }
    }
};

</script>
