<template>
<div :class="[{messageavatar: messageAvatar}, 'profile-result-avatar', 'profile-image-' + user.avatartype, size, `profile-image-${scale}`, {'online-halo': user.online}]">
    <template v-if="showIcons">
        <a v-if="user.chat" href="#" :title="gt.mini_profile.join_in_chat" class="online" v-open-chat="user.chat">
            <i class="fa fa-comment pull-left text-success"></i>
        </a>
        <a v-else-if="user.messenger" href="#" :title="gt.mini_profile.send_im" class="online" v-open-messenger="user.id">
            <i class="fa fa-wechat pull-left text-success"></i>
        </a>
        <i v-else-if="user.online" class="fa fa-circle online" :title="gt.avatar.online_now"></i>
        <a v-if="user.links && user.links.photos && size !== 'medium' && user.photos > 0" class="profile-image-photos" :target="linkTarget"
            :href="user.links.photos" :title="gt.avatar.view_photos">
            <i class="fa fa-camera"></i>{{ user.photos }}
        </a>
    </template>
    <slot></slot>
    <button v-if="canRequestPhoto" class="btn btn-dark-ghost request-photo" @click.prevent="requestPhoto">{{ gt.request_photo }}</button>
    <loading v-if="loadingRequest"></loading>
    <a v-if="user.links && user.links.profile" :href="user.links.profile" :target="linkTarget">
        <div :class="['profile-image-background', avatarBackground]">
            <div v-if="avatar" :style="avatar"
                role="img" :aria-label="gt.avatar.aria_label.replace('%username', user.username || user.userName)"></div>
        </div>
    </a>
     <div v-if="!user.links || !user.links.profile" :class="['profile-image-background', avatarBackground]">
        <div v-if="avatar" :style="avatar"
            role="img" :aria-label="gt.avatar.aria_label.replace('%username', user.username || user.userName)"></div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex';

import ajax from 'Site/js/util/ajax.fetch';
import openChatDirective from 'Site/directive/openChat';
import openMessengerDirective from 'Messenger/directive/openMessenger';
import random from 'Site/js/util/random';

import Loading from 'Site/component/Loading.vue';

export default {
    name: 'Avatar',
    components: {
        Loading
    },
    computed: {
        ...mapState(['gt']),
        avatar() {
            if (this.user.avatarSmall && typeof this.user.avatarSmall === 'string' && this.size === 'medium') {
                return `background-image: url(${this.user.avatarSmall})`;
            } else if (this.user.avatar && typeof this.user.avatar === 'string' && this.size === 'large-thumbnail') {
                return `background-image: url(${this.user.avatar})`;
            } else if (this.user.avatarLarge && typeof this.user.avatar === 'string' && this.size === 'large-watermarked') {
                return `background-image: url(${this.user.avatarLarge})`;
            }

            if (this.user.avatar && this.user.avatar.media && this.user.avatar.media[this.size] && this.user.avatar.media[this.size].url) {
                return `background-image: url(${this.user.avatar.media[this.size].url})`;
            }
            return '';
        },
        avatarBackground() {
            const { genders } = this.user;
            return `avatar-${genders}${random(1, 4)}`;
        },
        canRequestPhoto() {
            return this.$store.state.siteConfig.user.id && this.size !== 'medium' && !this.avatar && !this.loadingRequest &&
                this.user.hasRequestedPhoto === false
                && !this.loadedRequest && this.showRequestPhoto && parseInt(this.$store.state.siteConfig.user.id, 10) !== this.user.id;
        }
    },
    data() {
        return {
            loadedRequest: false,
            loadingRequest: false
        };
    },
    directives: {
        openChat: openChatDirective,
        openMessenger: openMessengerDirective
    },
    methods: {
        requestPhoto() {
            this.loadingRequest = true;
            ajax.create('requestphoto', { user: this.user.id }).then((response) => {
                this.loadingRequest = false;
                this.loadedRequest = true;
                this.$store.dispatch('notify', { msg: response.message });
            }).catch(({ response }) => {
                response.then((error) => {
                    this.loadingRequest = false;
                    this.loadedRequest = true;
                    this.$store.dispatch('notify', { type: 'error', msg: error.message });
                });
            });
        }
    },
    props: {
        linkTarget: {
            type: String,
            default: '_self'
        },
        showIcons: {
            default: true
        },
        showRequestPhoto: {
            type: Boolean,
            default: true
        },
        scale: {
            type: Number
        },
        size: {
            type: String,
            default: 'large-thumbnail'
        },
        messageAvatar: {
            type: Boolean,
            default: false
        },
        user: {
            type: Object,
            required: true,
            default() {
                return {};
            }
        }
    }
};
</script>

<style lang="scss">
@import "~Site/scss/variables";

.profile-image-background {
    background-image: url('../images/avatars.png?1');
    background-repeat: no-repeat;
    background-size: 2100%;
    z-index: 0 !important;
    position: relative;
    div {
        background-size: cover;
    }
}

.profile-avatar {
    width: 150px;
    .profile-details {
        padding-left: 5px;
        padding-top: 5px;
        .username {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 84px;
            float: left;
        }
    }
}


.avatar-f1 {
    background-position: 0 0;
}
.avatar-f2 {
    background-position: 5.4% 0;
}
.avatar-f3 {
    background-position: 10.6% 0;
}
.avatar-f4 {
    background-position: 15.8% 0;
}

.avatar-mf1 {
    background-position: 21.1% 0;
}
.avatar-mf2 {
    background-position: 26.4% 0;
}
.avatar-mf3 {
    background-position: 31.6% 0;
}
.avatar-mf4 {
    background-position: 36.9% 0;
}

.avatar-ff1 {
    background-position: 42.2% 0;
}
.avatar-ff2 {
    background-position: 47.2% 0;
}
.avatar-ff3 {
    background-position: 52.7% 0;
}
.avatar-ff4 {
    background-position: 57.9% 0;
}

.avatar-mm1 {
    background-position: 63.2% 0;
}
.avatar-mm2 {
    background-position: 68.3% 0;
}
.avatar-mm3 {
    background-position: 73.5% 0;
}
.avatar-mm4 {
    background-position: 78.8% 0;
}

.avatar-m1 {
    background-position: 84.1% 0;
}
.avatar-m2 {
    background-position: 89.3% 0;
}
.avatar-m3 {
    background-position: 94.6% 0;
}
.avatar-m4 {
    background-position: 99.8% 0;
}

.profile-image- {
    background-color: #eee;
}

.profile-image-couple {
    background-color: #C4EAC4;
}

.profile-image-male {
    background-color: #B3D0EA;
}

.profile-image-female {
    background-color: #EFD7D7;
}

.profile-image-photos {
    background-color: rgba(0,0,0,0.3);
    color: #fff !important;
    padding: 1px 3px 0 3px;
    border-radius: 2px;
    position: absolute;
    &:hover {
        color: #fff;
    }
    i {
        padding-right: 5px;
    }
    z-index: 1;
}

.profile-image-verified {
    position: absolute;
    font-size: 16px;
    color: $brand-success;
    background-color: #fff;
    padding: 1px 2px;
    border-radius: 4px;
    z-index: 1;
}

.profile-result-details {
    padding-left: 5px;
    padding-top: 5px;
    border-top: 1px solid #eee;
    .username {
        @include text-overflow();
        max-width: 84px;
        float: left;
    }
}
.profile-result-user-icons {
    float: right;
    padding-right: 5px;
    .fa-user + .fa-user {
        margin-left: -4px;
    }
    &> i, a {
        padding-left: 2px;
    }
}

.profile-result-avatar.avatar-circle {
    border-radius: 50%;
    .profile-image-background {
        &, div {
            border-radius: 50%;
        }
    }
}

.profile-result-avatar.profile-image-70 {
    width: 70px;
    height: 70px;
    div {
        width: 70px;
        height: 70px;
    }
}

.user-avatar{
    .online {
        text-shadow: #fff 1px 0 0, #fff 1px 1px 0, #fff 0 1px 0, #fff -1px 0 0, #fff -1px -1px 0, #fff 0 -1px 0, #fff 1px 0 0;
        position: absolute;
        padding: 5px;
    }
    .avatar-25{
        width: 25px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        .profile-image-background, .mini-profile-image{
            height: 25px;
            width: 25px;
        }
        .profile-details{
            width: 25px;
            padding: 0 !important;
        }
        .online {
            display: none;
        }
    }
    .avatar-50{
        width: 50px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        .profile-image-background, .mini-profile-image{
            height: 50px;
            width: 50px;
        }
        .profile-details{
            width: 50px;
            padding: 0 !important;
        }
        .online {
            left: -5px;
            top: -5px;
        }
    }
    .avatar-75{
        width: 75px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        .profile-image-background, .mini-profile-image{
            height: 75px;
            width: 75px;
        }
        .profile-details{
            width: 75px;
            padding: 0 !important;
        }
        .online {
            left: 0;
            top: 0;
        }
    }
    .avatar-100{
        width: 100px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        .profile-image-background, .mini-profile-image{
            height: 100px;
            width: 100px;
        }
        .profile-details{
            width: 100px;
            padding: 0 !important;
        }
        .profile-image-verified{
            bottom: 6px!important;
            right: 6px!important;
        }
        .profile-image-verified.avatar-circle{
            bottom: 18px!important;
            right: 14px!important;
            font-size: 13px;
        }
        .contact-dropdown{
            position: absolute;
            z-index: 2;
            right: 5px;
            top: 37px;
            .dropdown-toggle{
                background-color: rgba(0,0,0,.3);
                color: #fff;
            }
        }
        .online {
            left: 5px;
            top: 0px;
        }
    }
    .avatar-150{
        width: 150px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        .profile-image-background, .mini-profile-image{
            height: 150px;
            width: 150px;
        }
        .profile-details{
            width: 150px;
            padding: 0 !important;
        }
        .online {
            left: 20px;
            top: 5px;
        }
    }
    .avatar-185{
        width: 185px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        .profile-image-background, .mini-profile-image{
            height: 185px;
            width: 185px;
        }
        .profile-details{
            width: 185px;
            padding: 0 !important;
        }
        .online {
            left: 30px;
            top: 5px;
        }
    }
    .avatar-circle{
        display:block;
        border-radius:50%;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .profile-image-background.avatar-circle{
        border-radius:50%;
        .mini-profile-image{
            background-position: center;
        }
    }
    .profile-details{
        text-align: center;
        margin-top: 5px;
        margin-bottom: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .username, .profile-result-user-icons{
            float: none !important;
            display: block;
            max-width: 100% !important;
        }
    }
    /* We have no avatar so set a default */
    .profile-avatar{
        .avatar-1{
            background-color: #efefef !important;
            background-image: url('../images/avatars.png?1');
            z-index: 0 !important;
            position: relative;
            background-position: 50% 0;
        }
    }
     @media (max-width: $screen-xs-max) {
        .avatar-1{
            height: 50px;
            width: 50px;
        }
        .avatar-100, .avatar-75, .avatar-1{
            .profile-image-background, .mini-profile-image {
                height: 75px;
                width: 75px;
            }
            .profile-details{
                width: 75px;
                height: 50px;
            }
            .profile-image-verified{
                font-size: 13px;
            }
        }
    }
}

.profile-result-avatar.messageavatar {
    position: relative;
    &.medium, &.medium div {
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }
}
.profile-result-avatar {
    position: relative;
    &.medium, &.medium div {
        width: 50px;
        height: 50px;
    }
    .loading {
        position: absolute;
        width: 100%;
        margin: 0 !important;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .online {
        text-shadow: #fff 1px 0 0, #fff 1px 1px 0, #fff 0 1px 0, #fff -1px 0 0, #fff -1px -1px 0, #fff 0 -1px 0, #fff 1px 0 0;
        z-index: 2;
        font-size: 1.3em;
        padding: 5px;
        position: absolute;
        top: 0;
        left: 1px;
        &.fa-circle {
            font-size: 1.2em;
        }
    }
    .profile-image-photos {
        position: absolute;
        top: auto !important;
        bottom: 5px;
        left: 5px;
    }
    .profile-image-verified {
        position: absolute;
        top: auto !important;
        left: auto !important;
        right: 5px;
        bottom: 5px;
    }
}
.request-photo {
    position: absolute;
    transform: translate(-50%,-50%);
    margin-right: -50%;
    top: 50%;
    left: 50%;
    z-index: 1;
}
</style>
