/**
 *
 * @param category - string
 * @param action - string
 * @param label - string
 * @param value - int
 */
export default function (category, action, label = null, value = null) {
    /* global ga */
    if (typeof ga !== 'undefined') {
        ga('send', 'event', category, action, label, value);
    } else {
        /* eslint no-console: 0 */
        console.error(category, action, label, value);
    }
}

export function pageView(url = window.location.href) {
    /* global ga */
    if (typeof ga !== 'undefined') {
        ga('set', 'page', url);
        ga('send', 'pageview');
    } else {
        /* eslint no-console: 0 */
        console.error('analytics-pageview', url);
    }
}
